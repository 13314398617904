<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card class="list-view-item overflow-hidden">
    <template slot="no-body">
      <div class="vx-row item-details no-gutter">
        <!-- IMG COL -->
        <div
          class="vx-col sm:w-1/4 w-full item-img-container bg-white flex items-center justify-center"
        >
          <img
            :src="item.ImageUrl"
            @error="replaceByDefault"
            class="grid-view-img p-4"
          />
        </div>

        <!-- ITEM NAME & DESC COL -->
        <div class="vx-col sm:w-1/2">
          <div class="p-4 pt-6">
            <slot name="item-meta">
              <h6 class="item-name font-semibold mb-1">
                {{ item.Name.find((n) => (n.Language = 'EST')).Value }}
              </h6>
              <!--                            <p class="text-sm mb-4">By <span class="font-semibold cursor-pointer">Google</span></p>-->
              <p class="item-description text-sm">Item Code: {{ item.Code }}</p>
              <!--<P class="item-description text-sm mt-2">
                            {{item.Description ? item.Description[0].Name.find((n) => n.Language = 'EST').Value : 'NO DESCRIPTION'}}
                          </P>-->
              <P class="item-description text-sm mt-5">
                {{
                  item.ActiveSubstance
                    ? item.ActiveSubstance
                    : 'NO Active Substance'
                }}
              </P>
            </slot>
          </div>
        </div>

        <!-- PURCHASE COL -->
        <div
          class="vx-col sm:w-1/4 w-full flex items-center sm:border border-0 border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0"
        >
          <div class="p-4 flex flex-col w-full">
            <!--                        <div class="text-warning flex self-end border border-solid border-warning py-1 px-2 rounded">-->
            <!--                            <span class="text-sm mr-1">5</span>-->
            <!--                            <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />-->
            <!--                        </div>-->

            <!--                        <div class="my-6">-->
            <!--                            <h5 class="font-bold text-center">$999</h5>-->
            <!--                            <span class="text-grey flex items-start justify-center mt-1" v-if="item.free_shipping">-->
            <!--                                <feather-icon icon="ShoppingCartIcon" svgClasses="w-4 h-4" />-->
            <!--                                <span class="text-sm ml-2">Free Shipping</span>-->
            <!--                            </span>-->
            <!--                        </div>-->

            <!-- SLOT: ACTION BUTTONS -->
            <slot name="action-buttons" />
          </div>
        </div>
      </div>
    </template>
  </vx-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      placeholder_image: 'https://i.stack.imgur.com/y9DpT.jpg',
    };
  },
  computed: {
    isInCart() {
      return (itemId) => this.$store.getters['eCommerce/isInCart'](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters['eCommerce/isInWishList'](itemId);
    },
  },
  methods: {
    toggleItemInWishList(item) {
      this.$store.dispatch('eCommerce/toggleItemInWishList', item);
    },
    additemInCart(item) {
      this.$store.dispatch('eCommerce/additemInCart', item);
    },
    cartButtonClicked(item) {
      this.isInCart(item.objectID)
        ? this.$router.push('/apps/eCommerce/checkout').catch(() => {})
        : this.additemInCart(item);
    },
    navigate_to_detail_view() {
      this.$router
        .push({
          name: 'ecommerce-item-detail-view',
          params: { item_id: this.item.objectID },
        })
        .catch(() => {});
    },
    replaceByDefault(e) {
      e.target.src = this.placeholder_image;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-view-item {
  .item-name,
  .item-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .item-name {
    -webkit-line-clamp: 2;
  }

  .item-description {
    -webkit-line-clamp: 5;
  }

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
    height: 120px;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
